var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    attrs: {
      "line-height": 4
    }
  }, [_vm._v("Reset your password")]), !_vm.isResetPasswordSent ? [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'EMAIL',
      "name": "natural_person[email]",
      "validation": _vm.$v.email
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.$touch();
      }
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _vm._m(0), _c('sygni-rounded-button', {
    staticClass: "black filled",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        return _vm.sendRequestResetPassword();
      }
    }
  }, [_vm._v(" Reset password ")])] : _vm._e()], 2), _vm.isResetPasswordSent ? _c('div', {
    staticClass: "remind-password-sent"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/accept.svg")
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("Your password reset has been sent.")]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" Check your email.")])]) : _vm._e()])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "security-text"
  }, [_vm._v(" Your connection is protected by SSL "), _c('img', {
    attrs: {
      "src": require("@/assets/icons/padlock.svg")
    }
  })]);
}]

export { render, staticRenderFns }